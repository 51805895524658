import { defineStore } from 'pinia'
import { reactive } from 'vue'
import router from '@/router'
import permissionsByUserType from '@/constants/config/permissions'
import { useConfigStore } from '@/stores/config'
import { useShoppingStore } from '@/stores/shopping'
import { formatDateTime } from '@/helpers/formatDate'

export const useUserStore = defineStore('user', {
  state: () => ({
    info: reactive({}),
    search: '',
    toggleSearch: false,
    blockedState: false,
    filterByClient: {
      list: [],
      id: null,
      item: null,
      price_num: null
    },
    clientSelected: null,
    clientSelectedInfo: reactive({}),
    clientCreditConfirmReadedInfo: false,
    homeRouteByUserType: {
      superadmin: 'admin.users',
      admin: 'admin.orders_list',
      manager: 'admin.orders_list',
      seller: 'admin.clients',
      prospect: 'home',
      client: 'myAccount',
      client_credit: 'home',
      customer_service: 'admin.clients',
      fastenal: 'home',
      operations: 'admin.clients',
      default: ''
    },
    hasSeenVideo: false,
    isAgreementActive: false,
    agreementClientOptions: [],
    currentRouteName: '',
    agreementCategorySearch: false,
    agreementCategoriesIDs: [],
    agreementCategories: [],
    agreementLoginShowInfo: false
  }),
  getters: {
    isSuperadminArletOrKarla: (state) => {
      const arletOrKarlaID = [45, 248]
      if (state.info?.user_type === 'superadmin') return true
      if (state.info?.user_type === 'admin') return arletOrKarlaID.includes(state.info?.id)
      return false
    },
    getIsAgreementActive: (state) => state.isAgreementActive,
    getAgreementClientOptions: (state) => state.agreementClientOptions,
    getAgreementNameOnCart: (state) => (id) =>
      state.agreementCategories.find((category) => category.id === id)?.option_type,
    getAgrementLoginShowInfo: (state) => state.agreementLoginShowInfo,
    getAgreementCategories: (state) => [state.agreementCategoriesIDs, state.agreementCategories],
    getAgreementCategory: (state) => state.agreementCategorySearch,
    getFilterByClient() {
      return this.filterByClient
    },
    getZoneUser: (state) => state.info?.zone,
    getHasSeenVideo: (state) => state.hasSeenVideo,
    isBalanceActive: (state) => state.info?.balance > 0,
    requireChangePassword: (state) => state.info?.change_password,
    getIsReadedClientCreditConfirm: (state) => state.clientCreditConfirmReadedInfo,
    getHomeRouteByUserType: (state) => state.homeRouteByUserType[state?.userType || 'default'],
    hasPermission: (state) => (routeName) => {
      const userType = state.userType
      const userStore = useUserStore()
      const isAgreementActive = userStore.isAgreementActive
      const agreementRoutes = [
        'agreement',
        'agreement.shopping_cart',
        'agreement.search_products',
        'agreement.productdetail'
      ]
      const permissions = permissionsByUserType[userType] || permissionsByUserType.default
      if (userType === 'client' && isAgreementActive) {
        permissions.push(...agreementRoutes)
      }
      return permissions.includes(routeName)
    },
    getBlockedNote: (state) => state.blockedState,
    isBlocked: (state) => !!state.blockedState,
    toggleSearchValue: (state) => state.toggleSearch,
    searchValue: (state) => state.search,
    userInfo: (state) => state.info,
    userName: (state) => state.info?.name,
    phoneNumber: (state) => state.info?.phone_number,
    email: (state) => state.info?.email,
    userType: (state) => state.info?.user_type,
    isNew: (state) => state.info?.is_new,
    firstLetterName: (state) => state.info?.name?.charAt(0),
    managerType: (state) => {
      if (state.info?.user_type === 'manager') {
        return state.info?.manager_type
      }
      return null
    },
    isAdmin: (state) => state.info?.user_type === 'admin',
    isClient: (state) => state.info?.user_type === 'client',
    isClientProspect: (state) => state.info?.user_type === 'prospect',
    isClientCredit: (state) => state.info?.user_type === 'client_credit',
    isClientFastenal: (state) => state.info?.user_type === 'fastenal',
    isSuperadmin: (state) => state.info?.user_type === 'superadmin',
    isSeller: (state) => state.info?.user_type === 'seller',
    isUserCanSimulate: (state) =>
      state.info?.user_type === 'seller' || state.info?.user_type === 'manager',
    isManager: (state) => state.info?.user_type === 'manager',
    isCustomerService: (state) => state.info?.user_type === 'customer_service',
    isExcludedUserByClientNum: (state) => {
      const excludedOfMyAccountViewUser = [24509]
      return excludedOfMyAccountViewUser.includes(state.info?.client_num)
    },
    routeToDasboardByUserType: (state) => {
      switch (state.info?.user_type) {
        case 'superadmin':
        case 'admin':
          return 'admin.users'
        case 'manager':
          return 'admin.clients'
        case 'seller':
          return 'admin.clients'
        case 'prospect':
          return 'signUpProspect'
        case 'client':
          return 'myAccount'
        case 'operations':
          return 'admin.clients'
        default:
          return ''
      }
    },
    actionPermissionsAdminProductListByUserType: (state) => {
      const permissions = {
        superadmin: ['all'],
        admin: ['openPreviewImage', 'addProducts', 'openDataSheet', 'changePage'],
        default: []
      }
      return permissions[state.info?.user_type] || permissions.default
    },
    canExportClients: (state) => {
      return ['superadmin'].includes(state.info?.user_type) || state.info?.id === 45
    },
    canFilterClients: (state) => {
      return ['superadmin', 'admin', 'manager', 'operations'].includes(state.info?.user_type)
    },
    canFilterOrders: (state) => {
      return ['superadmin', 'admin', 'manager', 'customer_service', 'operations'].includes(
        state.info?.user_type
      )
    },
    canEnterDashboard: (state) => {
      return (
        ['superadmin', 'admin', 'manager', 'seller', 'manager', 'operations'].includes(
          state.info?.user_type
        ) || false
      )
    },
    canViewMyAccount: (state) => {
      return (
        ['superadmin', 'admin', 'manager', 'seller', 'manager', 'operations'].includes(
          state.info?.user_type
        ) || false
      )
    },
    canViewMyAccountByClientNum: (state) => {
      return (
        ['superadmin', 'admin', 'seller', 'manager', 'operations'].includes(
          state.info?.user_type
        ) || false
      )
    },
    canViewProductsByClientNum: (state) => {
      return (
        ['superadmin', 'seller', 'manager', 'operations'].includes(state.info?.user_type) || false
      )
    },
    client_num: (state) => state.info?.client_num,
    agreementStateByRoute: (state) => {
      const isAgreementRoute =
        state?.currentRouteName?.includes('agreement') &&
        !state?.currentRouteName?.includes('admin')
      return (isAgreementRoute && state.isAgreementActive) || false
    },
    agreementState: (state) => state.isAgreementActive
  },
  actions: {
    setFilterByClient(key, value, client_id = false) {
      if (key === 'item') {
        this.filterByClient['price_num'] = value.price_num
      }
      if (client_id) {
        if (this.filterByClient['id'] === client_id) {
          this.filterByClient[key].addresses = value.addresses
          return
        }
      }
      this.filterByClient[key] = value
    },
    updateHasSeenVideo(value) {
      this.hasSeenVideo = value
    },
    updateClientCreditConfirmReadedInfo(value) {
      this.clientCreditConfirmReadedInfo = value
    },
    updateToggleSearch() {
      this.toggleSearch = !this.toggleSearch
    },
    updateSearch(value) {
      this.search = value
      this.toggleSearch = !this.toggleSearch
    },
    updateBlockedState(value) {
      this.blockedState = value
    },
    updateAgreementLoginShowInfo(value) {
      this.agreementLoginShowInfo = value
    },
    updateAgreementLogic(value = false) {
      this.isAgreementActive = value
    },
    updateCurrentRoute(routeName) {
      this.currentRouteName = routeName
    },
    updateAgreementCategory(value) {
      this.agreementCategorySearch = value
    },
    updateAgreementCategories(ids, complete) {
      this.agreementCategoriesIDs = ids
      this.agreementCategories = complete
    },
    updateUserInfo(response, requireRedirect = true) {
      const shoppingStore = useShoppingStore()
      const configStore = useConfigStore()
      const isLogicCreditBalanceActive = configStore.getIsLogicCreditBalanceByUserActive
      response.data.agreement_date = response.data?.agreement_date
        ? formatDateTime(response.data?.agreement_date, 'YYYY-MM-DD')
        : null
      const { agreement, balance, agreement_date, option_selected, client_options } = response.data
      const isAgreementExpired = agreement_date && new Date(agreement_date) < new Date()
      this.info = response.data
      const isOutOfBalance = balance <= 0
      if (
        isAgreementExpired ||
        !isLogicCreditBalanceActive ||
        option_selected === 'credit_note' ||
        isOutOfBalance
      ) {
        shoppingStore.updateBalanceLogic(false)
        this.isAgreementActive = false
      } else if (agreement && option_selected !== 'credit_note' && isLogicCreditBalanceActive) {
        shoppingStore.updateUserBalance(balance)
        shoppingStore.updateBalanceLogic(true)
        this.isAgreementActive = true
        this.agreementClientOptions = option_selected ? [option_selected] : client_options
      }
      const excludedOfMyAccountViewUser = [24509]
      if (requireRedirect) {
        if (excludedOfMyAccountViewUser.includes(this.info.client_num)) {
          router.push('/')
        } else {
          router.push({ name: this.homeRouteByUserType[this.userType] })
        }
      }
    }
  },
  persist: {
    enabled: true // Asegúrate de que esté habilitada correctamente.
  }
})
