<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="text-center me-2">
      <img
        width="80"
        height="12"
        src="@/assets/images/sidebarAdminLogo.svg"
        class="img-fluid"
        alt="Logo de la página"
      />
    </div>
    <div class="text-start">
      <p class="mb-0 fw-semibold">Dinámica en línea</p>
    </div>
  </div>
  <p class="mt-5 mb-4"><b>Panel de control</b></p>
  <template v-for="option in optionByUser" :key="option.label">
    <div class="mb-5">
      <a
        class="text-decoration-none text-black sidebarOptions fw-medium cursor-pointer"
        @click="goToRoute(option.link)"
        v-if="!option.hasSubMenu"
        :class="{ active: option.key.includes($route.name) }"
      >
        {{ option.label }}
      </a>
      <Dropdown v-else-if="userPermissionToSubOptions(option)" :subOption="option" />
    </div>
  </template>
  <div>
    <a
      class="text-decoration-none text-black sidebarOptions fw-medium cursor-pointer"
      @click="authStore.logout()"
      >Salir</a
    >
  </div>
</template>

<script setup>
import options from '@/constants/config/menu.js'
import permissionsByUserType from '@/constants/config/permissions'
import Dropdown from '@/components/layout/admin/menu/MenuDropdown.vue'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const configStore = useConfigStore()
const userStore = useUserStore()
const userType = computed(() => userStore.userType)
const isAgreementActive = computed(() => userStore.isAgreementActive)
const permissions = computed(() => {
  const permissionsBase = permissionsByUserType
  if (isAgreementActive.value) {
    permissionsBase.client.push('agreement')
    permissionsBase.client.push('agreement.shopping_cart')
    permissionsBase.client.push('agreement.search_products')
  }
  return permissionsBase[userType.value]
})

permissionsByUserType[userType.value]
const authStore = useAuthStore()
const router = useRouter()
function goToRoute(routeName) {
  if (!routeName) return
  configStore.toogleAdminSidebar()
  router.push({
    name: routeName
  })
}

const userPermissionToSubOptions = (option) => {
  return option.subMenuOptions.some((subOption) => {
    const { key } = subOption
    return key.some((key) => permissions.value.includes(key))
  })
}

const optionByUser = computed(() => {
  return options.filter((option) => {
    const hasPermissionToMenu = option.key.some((key) => permissions.value?.includes(key))
    return hasPermissionToMenu ? option : null
  })
})
</script>
