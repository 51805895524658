import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import { useLoadingStore } from '@/stores/loading'
import { refreshAccessToken } from '@/helpers/auth'
import Swal from 'sweetalert2'

// Axios backend instance
const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    Accept: 'application/json'
  },
  responseType: 'json'
})

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (token) {
      promise.resolve(token)
    } else {
      promise.reject(error)
    }
  })
  failedQueue = []
}

// Interceptors for request
instance.interceptors.request.use(
  (config) => {
    const authStoreData = useAuthStore()
    const loadingStoreData = useLoadingStore()

    loadingStoreData.startLoading()
    if (authStoreData.isLoggedInWithValidationOfTokenExpiration) {
      config.headers.Authorization = `Bearer ${authStoreData.getToken}`
    }

    return config
  },
  (error) => {
    Swal.fire({
      title: 'Error',
      text: 'Error al configurar la solicitud.',
      icon: 'error',
      timer: 2000,
      showConfirmButton: false
    })
    return Promise.reject(error)
  }
)

// Interceptors for response
instance.interceptors.response.use(
  (res) => {
    const loadingStoreData = useLoadingStore()
    loadingStoreData.finishLoading()
    return res
  },
  async (error) => {
    const loadingStoreData = useLoadingStore()
    const authStoreData = useAuthStore()
    loadingStoreData.finishLoading()

    const status = error.response?.status
    const originalRequest = error.config
    const message = error.response?.data?.message || 'Error desconocido'
    const endpoint = error.config?.url
    const grantTypeIsPwd = error.config?.data
      ? JSON.parse(error.config?.data)?.grant_type === 'password'
      : false
    const grantTypeIsRefreshToken = error.config?.data
      ? JSON.parse(error.config?.data)?.grant_type === 'refresh_token'
      : false

    if (status === 401 && !originalRequest._retry) {
      if (grantTypeIsPwd) {
        Swal.fire({
          title: 'Error de Autenticación',
          text: 'Usuario o contraseña incorrectos.',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false
        })
        return Promise.reject(error)
      }
      if (!authStoreData.refresh_token || grantTypeIsRefreshToken) {
        authStoreData.logout()
        Swal.fire({
          title: 'Sesión Expirada',
          text: 'Por favor, inicia sesión nuevamente.',
          icon: 'warning',
          timer: 3000,
          showConfirmButton: false
        })
        return Promise.reject(error)
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            return axios(originalRequest)
          })
          .catch((err) => Promise.reject(err))
      }

      originalRequest._retry = true
      isRefreshing = true

      try {
        const res = await refreshAccessToken(authStoreData.refresh_token)
        authStoreData.login(res.data)
        processQueue(null, res.data.access_token)

        originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`
        return axios(originalRequest)
      } catch (refreshError) {
        processQueue(refreshError, null)
        authStoreData.logout()
        Swal.fire({
          title: 'Error de Autenticación',
          text: 'No se pudo renovar tu sesión. Por favor, inicia sesión nuevamente.',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false
        })
        return Promise.reject(refreshError)
      } finally {
        isRefreshing = false
      }
    } else if (status >= 500) {
      // Errores del servidor
      console.error('Error del Servidor:', error.response)
      Swal.fire({
        title: `Error del Servidor ${status}`,
        text: 'El servidor encontró un problema inesperado. Por favor intenta más tarde.',
        icon: 'error',
        footer: `<strong>Endpoint:</strong> ${endpoint}`,
        timer: 3000,
        showConfirmButton: false
      })
    } else if (status >= 400) {
      // Errores del cliente
      console.warn('Error de Cliente:', error.response)
      Swal.fire({
        title: `Error ${status}`,
        text: message || 'Se produjo un error al procesar tu solicitud.',
        icon: 'error',
        footer: `<strong>Revisar:</strong> los datos enviados o contactar soporte.`,
        timer: 3000,
        showConfirmButton: false
      })
    } else {
      // Otros errores
      console.warn('Error inesperado:', error)
      Swal.fire({
        title: 'Error inesperado',
        text: 'Se produjo un error desconocido. Por favor intenta más tarde.',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false
      })
    }

    return Promise.reject(error)
  }
)

export default instance
