// Vue
import App from './App.vue'
import { createApp } from 'vue'

// Pinia
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

// Lottie
import Vue3Lottie from 'vue3-lottie'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Fontawesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

import registerComponents from '@/fontawesome/icons'

// Router
import router from './router'

// Bootstrap
import 'bootstrap'

// Datepicker
import Datepicker from 'vue3-datepicker'
// PDF Vue 3
import vue3ToPdf from 'vue3-to-pdf'

// v-calendar
import { setupCalendar } from 'v-calendar'

import { formatDateTime } from '@/helpers/formatDate'
import { actionsStatusBadge } from '@/helpers/statusBadge'
import { initSentry } from './sentry' // Importa la configuración de Sentry
import directives from '@/helpers/directives'
import { formatCurrency } from '@/helpers/formatCurrency'

import { setSiteConfig } from '@/helpers/setSiteConfig'

const environment = import.meta.env.VITE_ENVIRONMENT // Agrega esta línea

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Nueva versión disponible. Recargando...')
    window.location.reload()
  })
}

const app = createApp(App)

// Register directives
app.use(directives)

// Register v-calendar
app.use(setupCalendar, {})

// Create currency constant function
const currency = Intl.NumberFormat('es-MX')
app.config.globalProperties.$currency = currency

app.component('v-select', vSelect)

app.config.globalProperties.$formatcurrency = formatCurrency
app.config.globalProperties.$formatdatetime = formatDateTime
app.config.globalProperties.$actionsstatusbadge = actionsStatusBadge

// Example
// {{ $formatcurrency(value) }}

// Create pinia
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
app.use(pinia)

// Set site config
setSiteConfig().then(() => {
  // Register lottie
  app.use(Vue3Lottie)

  // Register fontawesome
  registerComponents()
  app.component('font-awesome-icon', FontAwesomeIcon)
  app.component('font-awesome-layers', FontAwesomeLayers)
  app.component('font-awesome-layers-text', FontAwesomeLayersText)

  if (environment === 'production') initSentry(app, router) // Inicializa Sentry con la instrumentación de vue-router

  // Register router
  app.use(router)

  // Register Datepicker
  app.component('DatePicker', Datepicker)
  // Register PDF
  app.use(vue3ToPdf)

  // Mount
  app.mount('#app')
})
