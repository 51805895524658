import { defineStore, getActivePinia } from 'pinia'
import { ref } from 'vue'
import router from '@/router'
import permissionsByUserType from '@/constants/config/permissions'
import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'
import Swal from 'sweetalert2'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: ref(null),
    expires_in: ref(null), // Tiempo en segundos para que expire
    created_at: ref(null), // Fecha en la que se generó el token
    refresh_token: ref(null),
    urlToGoBeforeLogin: ref(null),
    alreadyLandBeforeLogin: ref(null)
  }),
  getters: {
    getAlreadyLandBeforeLogin() {
      return this.alreadyLandBeforeLogin
    },
    getUrlToGoBeforeLogin() {
      return this.urlToGoBeforeLogin
    },
    hasUrlToGoBeforeLogin() {
      return !!this.urlToGoBeforeLogin
    },
    getToken() {
      // Validar si el token ha expirado
      const now = new Date()
      const expirationDate = new Date(this.created_at + this.expires_in * 1000)
      if (now >= expirationDate) {
        Swal.fire({
          title: 'Sesión Finalizada',
          text: 'Por seguridad, tu acceso ha expirado. Estamos renovando tu acceso automáticamente. Si esto no funciona, te pediremos que inicies sesión nuevamente.',
          icon: 'info',
          timer: 5000,
          showConfirmButton: false
        })
        return null
      }
      return this.token
    },
    isLoggedIn() {
      return !!this.token
    },
    isLoggedInWithValidationOfTokenExpiration() {
      // Determinar si el usuario está logueado y si el token no ha expirado
      if (!this.token) return false
      const now = new Date()
      const expirationDate = new Date(this.created_at + this.expires_in * 1000)
      if (now >= expirationDate) {
        Swal.fire({
          title: 'Sesión Finalizada',
          text: 'Por seguridad, tu acceso ha expirado. Estamos renovando tu acceso automáticamente. Si esto no funciona, te pediremos que inicies sesión nuevamente.',
          icon: 'info',
          timer: 5000,
          showConfirmButton: false
        })
        return false
      }
      return true
    },
    hasPermission: () => (routeName) => {
      const userStore = useUserStore()
      const userType = userStore.userType
      const isAgreementActive = userStore.isAgreementActive
      if (isAgreementActive.value) {
        permissionsByUserType.client.push('agreement')
        permissionsByUserType.client.push('agreement.shopping_cart')
        permissionsByUserType.client.push('agreement.search_products')
      }
      const permissions = permissionsByUserType[userType] || permissionsByUserType.default
      return permissions.includes(routeName)
    }
  },
  actions: {
    defineUrlToGoBeforeLogin(routeName) {
      this.urlToGoBeforeLogin = routeName
      this.alreadyLandBeforeLogin = !routeName
    },
    alreadyLand() {
      this.alreadyLandBeforeLogin = false
    },
    login(response) {
      const { access_token, refresh_token, expires_in } = response
      this.token = access_token
      this.refresh_token = refresh_token
      this.expires_in = expires_in // Guardar el tiempo de expiración
      this.created_at = Date.now() // Guardar la fecha actual como creación del token
    },
    logout() {
      const userStore = useUserStore()
      const stores = getActivePinia()
      const configStore = useConfigStore()
      stores._s.forEach((store) => {
        if (store?.$id === 'config') return
        store.$reset()
      })
      configStore.sidebarVisible = false
      configStore.adminSidebarVisible = false
      this.urlToGoBeforeLogin = null
      this.alreadyLandBeforeLogin = null
      this.token = null
      this.refresh_token = null
      this.expires_in = null
      this.created_at = null
      userStore.updateHasSeenVideo(true)
      router.push({ name: 'login' })
    }
  },
  persist: true
})
