import configFooterDinamica from '@/constants/navigation/footer/footerConfigDinamica'
import configFooterFastenal from '@/constants/navigation/footer/footerConfigFastenal'
// import configFooterToolcenter from '@/constants/navigation/footer/footerConfigToolcenter'
const configs = {
  fastenal: configFooterFastenal,
  dinamica: configFooterDinamica,
  default: configFooterDinamica
}

export default configs
