import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    loader: ref({
      count: 0,
      message: ''
    })
  }),
  getters: {
    getLoaderCount() {
      return this.loader.count
    },
    getLoaderMessage() {
      return this.loader.message
    },
    isLoading() {
      return this.loader.count > 0
    }
  },
  actions: {
    startLoading(value = 1) {
      const temp = this.loader.count + value
      if (temp >= 0) {
        this.loader.count = temp
      }
    },
    finishLoading(value = 1) {
      const temp = this.loader.count - value
      if (temp >= 0) {
        this.loader.count = temp
      }
    },
    setLoaderMessage(message = '') {
      if (this.loader.message == '' && message == '') {
        return
      }
      this.loader.message = message
    },
    setLoaderMessageWTimeout(message = '') {
      if (this.loader.message == '' && message == '') {
        return
      }
      this.loader.message = message
      setTimeout(() => {
        this.loader.message = ''
      }, 5000)
    }
  }
})
