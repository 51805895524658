const brandName = import.meta.env.VITE_BRAND_NAME

const footerSections = {
  baseApp: {
    titleSection: 'Dinámica Ferretera',
    options: [
      {
        title: 'Acerca de nosotros',
        routeNamePublic: 'about_public',
        routeName: 'about'
      },
      {
        title: 'Bolsa de trabajo',
        routeNamePublic: 'contact_public',
        routeName: 'contact'
      }
    ]
  },
  services: {
    titleSection: 'Servicios',
    options: [
      {
        title: 'Dashboard',
        key: 'dashboard',
        routeName: 'dashboard'
      },
      {
        title: 'Información',
        key: 'info',
        routeName: 'myAccount'
      },
      {
        title: 'Estatus de pedidos',
        key: 'orders',
        routeName: 'myAccount',
        queryParams: { activeView: 'orders' }
      },
      {
        title: brandName === 'dinamica' ? 'Pedidos guardados' : 'Cotizaciones',
        key: 'quotations',
        routeName: 'myAccount',
        queryParams: { activeView: 'quotations' }
      },
      {
        title: 'Registrate',
        key: 'signUpProspect',
        routeName: 'signUpProspect'
      },
      {
        title: 'Login',
        key: 'login',
        routeName: 'login'
      }
    ]
  },
  customerSupport: {
    titleSection: 'Atención al cliente',
    options: [
      {
        title: 'Contacto',
        routeNamePublic: 'contact_public',
        routeName: 'contact'
      },
      {
        title: 'Ayuda',
        routeNamePublic: '',
        routeName: '',
        anotherLogic: true,
        action: 'infoVideo'
      }
    ]
  },
  policy: {
    titleSection: 'Políticas',
    options: [
      {
        title: 'Aviso de privacidad',
        routeNamePublic: 'privacy_policy_public_2',
        routeName: 'privacy_policy'
      },
      {
        title: 'Términos y condiciones',
        routeNamePublic: 'terms_and_conditions_public_2',
        routeName: 'terms_and_conditions'
      }
    ]
  },
  socialNetworks: [
    {
      title: 'Facebook',
      icon: 'fa-brands fa-facebook-square',
      url: 'https://www.facebook.com/oficialdinamicaferretera'
    }
  ]
}
const servicesOptionsByUserType = {
  superadmin: ['allOptions'],
  admin: ['allOptions'],
  operations: ['allOptions'],
  manager: ['dashboard', 'logout'],
  seller: ['allOptions'],
  prospect: ['signUpProspect'],
  client: ['info', 'orders', 'quotations', 'logout'],
  client_credit: ['orders', 'logout'],
  fastenal: ['logout', 'quotations'],
  noUser: ['signUpProspect', 'login']
}

export default { footerSections, servicesOptionsByUserType }
