export default (axios) => ({
  // CRUD
  list(payload, endpoint = 'orders') {
    return axios.get(`v1/${endpoint}`, { params: payload })
  },
  create(client = false, data, endpoint = 'orders') {
    data.source_type = 'web'
    if (!client) {
      return axios.post(`v1/${endpoint}`, data)
    }
    const payload = {
      ...data,
      client_id: client
    }
    return axios.post(`v1/${endpoint}`, payload)
  },
  show(id, endpoint = 'orders') {
    return axios.get(`v1/${endpoint}/${id}`)
  },
  update(id, data, endpoint = 'orders') {
    data.source_type = 'web'
    return axios.put(`v1/${endpoint}/${id}`, data)
  },
  destroy(id, endpoint = 'orders') {
    return axios.delete(`v1/${endpoint}/${id}`)
  },
  cancel(id, endpoint = 'orders') {
    return axios.post(`v1/${endpoint}/${id}/cancel`)
  },
  detail(id, endpoint = 'orders') {
    return axios.get(`v1/${endpoint}/${id}/current_user_show`)
  },
  backOrderProducts(payload) {
    return axios.post('v1/products/backorder', payload)
  },
  deactiveBackOrders(payload) {
    return axios.post('v1/orders/deactivate_backorder', payload)
  },
  cancelProducts(id, payload, endpoint = 'orders') {
    return axios.post(`v1/${endpoint}/${id}/cancel_product`, payload)
  },
  summaryInfo(payload) {
    return axios.get(`v1/orders/summary`, { params: payload })
  },
  summaryMonths(payload) {
    return axios.get(`v1/orders/summary_months`, { params: payload })
  },
  // crescendo endpoint
  addCrescendo(id, payload) {
    return axios.post(`v1/orders/${id}/add_creshendo`, payload)
  },
  addCrescendoInvoice(id, payload) {
    return axios.post(`v1/orders/${id}/add_invoice`, payload)
  },
  addCrescendoCreditNote(id, payload) {
    return axios.post(`v1/orders/${id}/add_credit_note`, payload)
  },
  reactivateOrder(id) {
    return axios.post(`v1/orders/${id}/reactivate`)
  }
})
